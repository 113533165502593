import React from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import Container from 'audreys-ui/dist/common/src/components/Container'

import { defaultGetNewPost, defaultGetPageName } from '../../components/Post/utils'
import PostContext from '../../components/Post/context'
import capitalize from '../../utils/capitalize'

import { getSelectFunctions } from '../post/contents'

import defaultPostTypeConfig from '../post'
import Content from './Content'
import New from './New'
import getConstants from './constants'

export function getPostTypeConfig(p = {}) {

    const { name = 'file' } = p

    return {
        getStatusManager: function getStatusManager(p = {}) {
            return defaultPostTypeConfig.getStatusManager({
                ...p,
                config: {
                    requiredDataForStatus: {
                        fileUrl: { type: String }
                    },
                    ...(p.config) ? p.config : {}
                }
            })
        },
        getConstants: p.getConstants || getConstants,
        setContents: function setContents(p = {}) {

            const { wapp } = p

            const r = defaultPostTypeConfig.setContents({
                ...p,
                name
            })

            const contentProps = wapp.contents.get(name)

            wapp.contents.add({
                [name]: {
                    ...contentProps,
                    robots: () => {
                        return 'noindex,nofollow'
                    }
                }
            })

            return r
        },
        requestForUserPage: async function requestForUserPage(p = {}) {
            return await defaultPostTypeConfig.requestForUserPage({
                ...p,
                name
            })
        },
        getPages: function() {
            return {
                content: Content,
                new: New
            }
        },
        getComponent: function(p) {

            const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

            const { context, appContext } = p
            const { routes } = appContext

            const { res, req } = context
            const route = res.wappResponse.route
            const requestPath = route.requestPath
            const user = req.wappRequest.user

            if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

                const isAdmin = (user && user._status_isFeatured) || (user && user._isEditor && name !== 'document')

                const pages = {
                    content: Content,
                    new: New,
                    ...p.pages ? p.pages : {}
                }

                if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                    return null
                }

                const requestKeys = [name + 'FindById']
                const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
                const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

                const isAuthorOrAdmin = (isAuthor || isAdmin)

                if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                    return null
                }

                function beforeSetUser(user) {

                    if (!user) {
                        return null
                    }

                    return {
                        ...user,
                        _status_isFeatured: user?._status_isFeatured || user?._isEditor
                    }
                }

                function getMenuContext(type, context) {
                    if (type === 'header') {
                        return {
                            ...context,
                            getPropsForMenuItemProps: (p) => {
                                const r = context.getPropsForMenuItemProps(p)
                                return {
                                    ...r,
                                    user: beforeSetUser(r.user)
                                }
                            }
                        }
                    }
                    return context
                }

                function getPostContext(context) {
                    return {
                        ...context,
                        user: beforeSetUser(context.user)
                    }
                }

                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {
                        pages,
                        ...name !== 'document' ? {
                            getPageName: (p) => {
                                return defaultGetPageName({
                                    ...p, user: {
                                        ...p.user ? p.user : {},
                                        _status_isFeatured: p.user?._status_isFeatured || p.user?._isEditor
                                    }
                                })
                            },
                            getMenuContext,
                            getPostContext
                        } : {}
                    },
                    ...name === 'file' ? {
                        archiveComponentProps: {
                            type: 'gallery',
                            accessForEditor: true,
                            beforeSetUser
                        }
                    } : {}
                })
            }
        },
        userPageFunctions: function(p) {

            const { context, appContext, postContext, enableMultipleDeletion = false } = p

            const n = p.name || name
            const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
            const N = capitalize(n)
            const Ns = capitalize(ns)

            const { res } = context

            const wappResponse = res.wappResponse
            const route = wappResponse.route
            const { params } = route
            const { pageType, _id } = params

            const selectFunctions = getSelectFunctions({ appContext, context, name })
            const utils = getUtils(context)
            const user = utils.getRequestUser()

            const isAuthor = (user?._id && user._id === _id)
            const isAdmin = user?._status_isFeatured || (user?._isEditor && name !== 'document')

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            const postsList = {}

            function Upload(props) {

                return (
                    <PostContext.Provider value={{ name: n, user, post: defaultGetNewPost({ user }) }}>
                        <Container {...props['container-type'] ? { ['container-type']: props['container-type'] } : {}}>
                            <New
                                style={{ minHeight: 0 }}
                                dropZone={name !== 'document'}
                                postsRef={postsList}
                                name={name}
                                onSuccess={async ({ files }) => {
                                    const newFiles = files && files.length && files.filter((file) => file && file._id)
                                    if (newFiles.length) {
                                        await postsList.actions?.updateFromUrl()
                                    }
                                }}
                                multiple={true}
                                args={(isAdmin && !isAuthor) ? { _author: _id } : {}}
                            />
                        </Container>
                    </PostContext.Provider>
                )

            }

            const r = defaultPostTypeConfig.userPageFunctions({
                ...p,
                name: n,
                nsPageProps: {
                    type: 'gallery',
                    selectable: (!pageType) ? undefined : false,
                    disablePageInfo: true,
                    selectFunctions: [
                        ...!pageType ? [
                            {
                                ...selectFunctions[0],
                                role: function({ posts }) {
                                    const deletionIsPossible = (posts?.length) ? posts.filter((post) => post._status_isNotDeleted && !post._status_isFeatured) : false
                                    return deletionIsPossible.length && deletionIsPossible.length === posts?.length && ((enableMultipleDeletion && isAuthorOrAdmin) || isAdmin)
                                }
                            }
                        ] : []
                    ],
                    onClickIsSelect: !isAdmin,
                    effect: function({ actions }) {
                        postsList.actions = actions
                    },
                    ...name === 'video' ? {
                        MenuItemProps: {
                            ContentComponentProps: {
                                ImageProps: {
                                    showProcessing: true,
                                    loaded: false
                                }
                            }
                        }
                    } : {},
                    firstPostMenu: {
                        MenuItemComponent: Upload
                    },
                    NoEntriesComponent: Upload,
                    ...name !== 'document' ? {
                        beforeSetUser: function beforeSetUser(user) {
                            if (!user) {
                                return null
                            }
                            return {
                                ...user,
                                _status_isFeatured: user?._status_isFeatured || user?._isEditor
                            }
                        }
                    } : {},
                    ...p.nsPageProps ? p.nsPageProps : {}
                }
            })

            //const {userStatusManager} = appContext;

            function addMenuItems(props) {

                const { appContext } = props
                const { menus, routes /*userStatusManager*/ } = appContext

                return [
                    {
                        label: function(p) {
                            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                        },
                        role: function(p) {
                            const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                            const postIsAdmin = (p.post && p.post._status_isFeatured)
                            return (postIsAdmin && (viewerIsAuthor || viewerIsAdmin))
                        },
                        order: 60,
                        items: [
                            {
                                label: menus['new' + N + 'Menu'],
                                href: routes[n + 'Route'] + '/new',
                                role: function(p) {
                                    const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor && name !== 'document'))
                                    const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                    return !!(isAdmin && isAuthor)
                                },
                                disableParentRoute: true
                            },
                            {
                                label: function(p) {
                                    const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                    return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                                },
                                href: function(p) {
                                    return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                                },
                                role: function(p) {
                                    const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor && name !== 'document'))
                                    const postIsAdmin = (p.post && p.post._status_isFeatured)
                                    return (isAdmin || postIsAdmin)
                                }
                            },
                            {
                                divider: true,
                                role: function(p) {
                                    return p.user && p.user._status_isFeatured
                                }
                            },
                            {
                                label: function() {
                                    return menus['deleted' + Ns + 'Menu']
                                },
                                href: function(p) {
                                    return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                                },
                                role: function(p) {
                                    const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id))
                                    const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                                    return isAuthor || isAdmin
                                }
                            }
                        ]
                    }
                ]
            }

            function addContentMenuItems() {
                return []
            }

            function getPageName({ user, post, page }) {

                const isAdmin = ((user && user._status_isFeatured) || (user && user._isEditor && name !== 'document'))
                const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                const isAuthorOrAdmin = (isAdmin || isAuthor)

                return (
                    (page === ns && !pageType) ||
                    (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                    (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                    (page === ns && pageType === 'banned' && isAdmin)
                ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

            }

            return {
                ...r,
                addMenuItems,
                addContentMenuItems,
                getPageName
            }
        },
        adminMenu: function(p) {
            const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
            defaultAdminMenu.order = 60

            const { routes } = p.appContext

            defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
                return item.href !== routes[name + 'Route'] + '/new'
            })

            return defaultAdminMenu
        },
        editorMenu: function(p) {

            if (name === 'document') {
                return []
            }

            const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
            defaultAdminMenu.order = 60

            const { routes } = p.appContext

            defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
                return item.href !== routes[name + 'Route'] + '/new'
            })

            return defaultAdminMenu
        }
    }
}

const postTypeConfig = getPostTypeConfig()

export default {
    ...postTypeConfig
}
