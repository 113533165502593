import { runPostTypesConfigSync } from '../../postTypes'

const messages = {
    notFoundNotAvailable: (p = {}) => {
        const { typeText = 'a tartalom', user } = p
        return `Ez ${typeText} ${user?._id ? '' : ''}nem elérhető`
    },
    notFoundLoginText: 'Jelentkezz be a fiókodba mert vannak oldalak, amiket az csak láthat, akinek van jogosultsága hozzá',
    thereAreNoEntries: 'Nincsenek bejegyzések',
    cookieShortDesc: 'A weboldalon design termékek mellett sütik (cookie) is találhatóak, a jobb felhasználói élmény érdekében.',
    leaveWarningMessage: 'Az oldal elhagyásával a mósodításaid elvesznek, biztosan ezt szeretnéd?',
    preventClickAgain: 'Az oldal betöltése folyamatban...',
    preventClickDone: 'Ez az oldal már betöltődött',
    offlineWarning: 'Nincs internet kapcsolat',
    onlineAgain: 'Az internetkapcsolat helyreállt',
    newReleaseWarningContent: 'Az Audrey\'s Home alkalmazás frissült, a további rendes működéshez az oldal újratöltése szükséges',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'messages' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default messages
